"use client"

import Image from "next/image";
import { useEffect } from 'react';
import { useRouter } from "next/navigation";
import { useCallback } from "react";

interface TicketsWithBestPricesProps {
  props: {
    origin: string;
    destination: string;
    image: string;
    price: number;
    link: string;
    altText: string;
  }[];
}

class ImagePicker {
  private images: string[];
  private usedImages: string[];

  constructor(images: string[]) {
    this.images = images.slice(); // Create a copy of the images array
    this.usedImages = [];
  }

  getRandomImage(): string {
    if (this.images.length === 0) {
      // All images have been used, reset the arrays
      this.images = this.usedImages.slice();
      this.usedImages = [];
    }

    const randomIndex = Math.floor(Math.random() * this.images.length);
    const selectedImage = this.images[randomIndex];

    // Remove the selected image from images array and add it to usedImages array
    this.images.splice(randomIndex, 1);
    this.usedImages.push(selectedImage);

    return selectedImage;
  }
}

// Usage
const images = [
  '/cidade1.webp',
  '/cidade2.webp',
  '/cidade3.webp',
  '/cidade4.webp'
];

const imagePicker = new ImagePicker(images);

export default function TicketsWithBestPrices(
  ticketArray: TicketsWithBestPricesProps
) {
  const router = useRouter();

  useEffect(() => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        router.push("myapp://");
      };
  }, []);

  const formatNumber = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const checkAndRedirect = useCallback(async (ticket: { origin: string; destination: string; link: string; }) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let adjustedOriginDate = new Date().toISOString().split('T')[0];
  
    if (isMobile) {
      const originDateObj = new Date();
      originDateObj.setDate(originDateObj.getDate() + 1);
      adjustedOriginDate = originDateObj.toISOString().split('T')[0];
    }
  
    const formatUrlPart = (part: string) => part.trim().replace(/\s+/g, '-').replace(/-+/g, '-').toLowerCase();
  
    // const mobileUrl = `myapp://passagem-de-onibus/${formatUrlPart(ticket.origin)}/${formatUrlPart(ticket.destination)}/${adjustedOriginDate}/false`;
    const desktopUrl = ticket.link;
  
    try {
      if (isMobile) {
        // await router.push(mobileUrl);
        setTimeout(async () => {
          await router.push(desktopUrl);
        }, 800);
      } else {
        await router.push(desktopUrl);
      }
    } catch (error) {
      await router.push(desktopUrl);
    }
  }, [router]);
  
  return (ticketArray?.props?.length > 0 &&
    <section className="mb-3 overflow-x-auto max-w-[100vw] hover:cursor-pointer">
      <div className="flex flex-col sm:flex-row flex-wrap justify-center gap-2">
        {ticketArray?.props.map((ticket, index) => (
          <div onClick={() => checkAndRedirect(ticket)} key={index} className="block w-full max-w-fit mx-auto relative mb-5">
            <Image loading={index === 0 ? 'eager' : "lazy"} className="rounded-[1.875rem] object-cover h-auto"
              src={ticket.image || imagePicker.getRandomImage()} alt={ticket.altText} width={377} height={377} />
            <div className="absolute top-0 p-5 bg-gradient-to-b from-gray-800 to-transparent w-full rounded-t-[1.875rem]">
              {ticket.origin && (
                <h3 className="text-white [text-shadow:_0_3px_3px_rgb(0_0_0_/_100%)]">
                  De {ticket.origin} para
                </h3>
              )}
              <h3 className="text-white text-3xl font-bold uppercase [text-shadow:_0_3px_3px_rgb(0_0_0_/_100%)]">{ticket.destination}</h3>
            </div>
            <div className="p-3 absolute bottom-0 w-full bg-gray-4 rounded-b-[1.875rem] flex justify-center align-center">
              {ticket.price > 0 ?
                <h4 className="text-white uppercase">A Partir de <span className="mb-2 text-2xl font-bold tracking-tight text-primary">{formatNumber.format(Number(ticket.price))}</span></h4>
                : <h4 className="text-2xl font-bold tracking-tight text-primary">Comprar</h4>}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
