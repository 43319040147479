"use client";
import { useEffect } from "react"

export default function Page() {
    useEffect(() => {
        // Send GTM event
        window?.dataLayer?.push({
            'Category': 'home',
            'Event': 'Visited home page'
        });
    }, []);
    return <></>
}
