import Image from 'next/image';
import arrowImage from '@/app/assets/arrow.svg';

interface TextWithArrowProps {
    text: string;
}

export default function TextWithArrow({ text }: TextWithArrowProps) {
    return (
        <div className="my-8 flex">
            <Image
                src={arrowImage}
                className="w-8 h-8"
                alt={"Seta indicando o percurso"}
                height={32}
                width={32}
            />
            <h2 className="ml-2 text-2xl"> {text}</h2>
        </div>
    );
}