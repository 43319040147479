"use client";
import Image from "next/image";
import embarcaLogo from "@/app/assets/embarca-ai-logo.svg";
import FormSearchCityDesktop from "./FormSearchCityDesktop";
import FormSearchCityMobile from "./FormSearchCityMobile";
import Link from "next/link";

interface Props {
  props?: {
    title: string;
    subtitle: string;
    bg_url: string;
    link: string;
  };
}

export default function SearchTicketsForm({ props }: Props) {
  //title e subtitle não estão sendo usados, pois atualmente está estático

  const { title, subtitle, bg_url, link } = props || {
    title: "Título",
    subtitle: "Subtítulo",
    bg_url: "/banner-background.webp",
  };

  return (
    <>
      {/* Form for mobile */}
      <section className="lg:hidden block bg-gray-5 rounded-b-3xl">
        <div className="flex justify-center">
          <Image
            className="mt-11"
            src={embarcaLogo}
            alt="Logo da Embarca.ai"
            priority
          />
        </div>
        <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
          <div className="flex flex-col text-center">
            <p className="text-white text-2xl">
              {/* {title} */}
              Passagens de ônibus <br />
              online Embarca.ai
            </p>
            <p className="text-white my-4 font-thin">
              {/* {subtitle} */}
              Viaje de <strong>ônibus</strong> com <strong>facilidade</strong> e{" "}
              <strong>economia</strong>
            </p>
          </div>
          <FormSearchCityMobile />
        </div>
      </section>
      {/* Form for desktop  */}
      <section
        style={{ "--image-url": `url(${bg_url})` } as React.CSSProperties}
        className={
          "relative hidden lg:flex bg-[image:var(--image-url)] bg-no-repeat bg-center bg-cover h-[20rem] mb-20"
        }
      >
        <div className="relative">
          <div className="absolute w-max top-1/4 left-[calc(50vw-36rem)] max-w-6xl ">
            <h1 className="text-white text-[2.5rem]">
              {/* {title} */}
              Passagens de ônibus <br />
              online Embarca.ai
            </h1>
            <p className="text-white mt-4 font-thin">
              {/* {subtitle} */}
              Viaje de <strong>ônibus</strong> com <strong>facilidade</strong> e{" "}
              <strong>economia</strong>
            </p>
          </div>
        </div>
        <FormSearchCityDesktop heightOption="higher" />
      </section>
    </>
  );
}
