import Image from 'next/image';
import logo90 from '@/app/assets/embarca-logo-90.svg'
import Link from 'next/link';

interface Route {
  origin: string;
  destination: string;
  buy_link: string;
}

export default function MainDestinationsCard({ props }: { props: Route }) {

  return (
    <div className="min-w-[377px] w-full max-w-[38rem] h-25 bg-gray-5 rounded-[1.875rem]">
      <div className="p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Image className="mr-3" src={logo90} alt="Ícone de trajeto com logo da Embarca" width={30} height={70} />
            <div>
              <p className="text-sm tracking-tight text-white mb-3 text-ellipsis">De {props.origin}</p>
              <p className="text-lg text-white">Para <strong className="text-lg uppercase text-ellipsis">{props.destination}</strong></p>
            </div>
          </div>
          <Link href={props.buy_link} className="bg-primary hidden sm:block font-bold rounded-lg p-5 uppercase text-sm">Comprar</Link>
        </div>
        <Link href={props.buy_link} className="bg-primary block sm:hidden w-full font-bold text-center rounded-lg p-3 mt-3 uppercase">Comprar</Link>
      </div>
    </div>
  )
}
