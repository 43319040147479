"use client";
import { useState } from 'react';
import TextWithArrow from '../text/TextWithArrow';
import PathCard from './RouteCard';

interface Props {
    props?: {
        name: string,
        id: number,
        destinations: Path[]
    }[]
}
interface Path { 
    origin: string; 
    destination: string; 
    buy_link: string; 
}

export default function MainDestionations(myProps?: Props) {
    const [activeDestination, setActiveDestination] = useState(0);

    const props = myProps?.props || [];

    return (props.length > 0 &&
        <section className="max-w-[100vw]">
            <TextWithArrow text="Principais destinos de ônibus" />
            <div className="flex flex-wrap justify-center max-w-screen mb-5">
                {props.map((destination: { name: string | undefined; }, index: number) => (
                    <h3 key={index} onClick={() => setActiveDestination(index)}
                        className={`p-5 m-1 w-[9.75rem] rounded-lg border text-sm text-center cursor-pointer ${activeDestination === index ? "bg-gray-5 text-primary" : "bg-[#D9D9D9] border-gray-300 hover:bg-gray-200"}`}>
                        {destination.name}
                    </h3>
                ))}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 place-items-center">
                {props[activeDestination].destinations.map((route: Path, index: number) => (
                    <PathCard key={index} props={route} />
                ))}
            </div>
        </section>
    )
}
